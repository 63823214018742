<template>
  <div class="login" @click.stop="">
    <header>Login</header>
    <form>
      <div class="user">
        <i class="iconfont icon-user"></i>
        <div class="inp">
          <el-input
            ref="uname"
            v-model="form.uname"
            type="text"
            placeholder="Username"
            @focus="focus('uname')"
            @keyup.enter.native="login()" />
          <i v-if="!check_uname" @click="clean('uname')" class="iconfont icon-err"></i>
        </div>
      </div>
      <div class="pwd">
        <i class="iconfont icon-pwd"></i>
        <div class="inp">
          <el-input
            ref="upwd"
            v-model="form.upwd"
            type="password"
            placeholder="Password"
            @focus="focus('upwd')"
            @keyup.enter.native="login()" />
          <i v-if="!check_upwd" @click="clean('upwd')" class="iconfont icon-err"></i>
        </div>
      </div>
      <div class="code">
        <i class="iconfont icon-security"></i>
        <div class="inp">
          <el-input
            ref="SecurityCode"
            v-model="form.cecurityCode"
            type="text"
            placeholder="SecurityCode"
            @focus="focus('cecurityCode')"
            @keyup.enter.native="login()" />

          <i v-if="!check_cecurity" @click="clean('cecurityCode')" class="iconfont icon-err"></i>
        </div>
        <div
          @click="
            getCode();
            clean('cecurityCode');
          "
          class="svg"
          v-if="cecurity_svg"
          v-html="cecurity_svg"></div>
        <div class="no-svg" v-else @click="getCode()">获取验证码</div>
      </div>
    </form>
    <footer>
      <div class="register" @click.stop.prevent="register">SignUp</div>
      <div class="btn" @click="login">LogIn</div>
      <el-checkbox class="checkbox" v-model="remember">记住登录</el-checkbox>
    </footer>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      form: {
        uname: "",
        upwd: "",
        cecurityCode: "",
      },
      check_upwd: true, // 密码,用户名,验证码是否有误
      check_uname: true,
      check_cecurity: true,
      cecurity_svg: null,
      token: null,
      remember: false,
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    ...mapMutations(["updateState", "SET_AUTHORIZATION"]),
    getCode() {
      this.axios.get("/user/code").then(res => {
        this.cecurity_svg = res.data.svg;
        this.token = res.data.token;
      });
    },
    login() {
      let token = this.token;
      let uname = this.form.uname.trim();
      let upwd = this.form.upwd.trim();
      let cecurityCode = this.form.cecurityCode.trim();
      if (!uname.length) this.check_uname = false;
      else if (!upwd.length) this.check_upwd = false;
      else if (!this.token || !cecurityCode.length) {
        this.getCode();
        this.check_cecurity = false;
      } else {
        let data = { uname, upwd, cecurityCode, token };
        this.axios.post("/user/login", data).then(res => {
          if (res.data.code === 202) {
            if (res.data.msg === "codeErr") {
              this.getCode();
              this.check_cecurity = false;
            } else if (res.data.msg === "unameErr") {
              this.check_uname = false;
              this.getCode();
              this.form.cecurityCode = "";
            } else if (res.data.msg === "upwdErr") {
              this.check_upwd = false;
              this.getCode();
              this.form.cecurityCode = "";
            }
          } else if (res.data.code === 200) {
            if (this.remember) {
              window.localStorage.setItem("authorization", res.data.data.authorization);
            }
            this.axios.defaults.headers.common["authorization"] = res.data.data.authorization;
            // this.SET_AUTHORIZATION(res.data.data.authorization);
            delete res.data.data.authorization;
            let data = new Array();
            let temp = Object.entries(res.data.data);
            for (let i = 0; i < temp.length; i++) {
              data[i] = {
                key: temp[i][0],
                value: temp[i][1],
              };
            }
            let subscribe = this.$store.subscribe((mutation, state) => {
              if (mutation.type === "updateState") {
                sessionStorage.setItem("store", JSON.stringify(this.$store.state));
                this.$router
                  .replace("/bookmark")
                  .then(() => {
                    subscribe();
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }
            });
            this.updateState(data);
          } else {
            console.log(res);
          }
        });
      }
    },
    clean(type) {
      // 清除用户输入
      if (type === "uname") {
        this.form.uname = "";
        this.check_uname = true;
        this.$refs.uname.focus();
      } else if (type === "upwd") {
        this.form.upwd = "";
        this.check_upwd = true;
        this.$refs.upwd.focus();
      } else {
        this.form.cecurityCode = "";
        this.check_cecurity = true;
        this.$refs.SecurityCode.focus();
      }
    },
    focus(type) {
      if (type === "uname") this.check_uname = true;
      else if (type === "upwd") this.check_upwd = true;
      else this.check_cecurity = true;
    },
    register() {
      this.$parent.showLogin = false;
      this.$parent.showRegister = true;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .login {
    width: 800px;
    padding-bottom: 60px;
    form {
      .user,
      .pwd,
      .code {
        > .inp {
          width: 350px;
          > .el-input {
            ::v-deep(input) {
              font-size: 30px;
            }
          }
        }
        > .svg,
        .no-svg {
          border: 0;
          position: absolute;
          right: -150px;
          bottom: 0px;
        }
        > .no-svg {
          line-height: 50px;
          color: #74ead5;
          font-size: 25px;
          right: -135px !important;
          cursor: pointer;
        }
      }
    }
    > footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-around;
    }
  }
}
@media screen and (max-width: 768px) {
  .login {
    width: 94.6667vw;
    padding-bottom: 10.6667vw;
    form {
      .user,
      .pwd,
      .code {
        width: 86.1333vw;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        > .inp {
          width: 100%;
          > .el-input {
            ::v-deep(input) {
              font-size: 4vw;
            }
          }
        }
      }
      .code {
        > .inp {
          > .el-input {
            ::v-deep(input) {
              font-size: 3.4667vw !important;
            }
          }
        }
      }
      > .svg,
      .no-svg {
        border: 0;
        position: absolute;
        right: -150px;
        bottom: 0px;
      }
      > .no-svg {
        line-height: 50px;
        color: #74ead5;
        font-size: 25px;
        right: -135px !important;
        cursor: pointer;
      }
    }
    > footer {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      .register {
        margin-bottom: 10px;
      }
      .btn {
        margin-bottom: 10px;
      }
    }
  }
}
.login {
  // 71/160
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(20, 20, 20, 0.6);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-radius: 20px;
  > header {
    color: white;
    font-size: 60px;
    font-weight: bolder;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    height: 210px;
    padding-bottom: 30px;
    .user,
    .pwd,
    .code {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      > i {
        color: white;
        font-size: 40px;
        padding-right: 20px;
      }
      > .inp {
        border-bottom: 4px solid white;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        > .el-input {
          ::v-deep(input) {
            background-color: transparent;
            color: white;
            border: 0;
          }
          width: 100%;
          height: 100%;
          ::v-deep(input::placeholder) {
            color: rgba(200, 200, 200, 1);
          }
          ::v-deep(input::-webkit-input-placeholder) {
            color: rgba(200, 200, 200, 1);
          }
          ::v-deep(input::-webkit-autofill) {
            box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.8) inset; // 背景颜色
            background-color: transparent;
            -webkit-text-fill-color: #fff; // input 的文字的颜色
            border: 0;
          }
        }
        > i {
          color: red;
          font-size: 30px;
        }
      }
    }
  }
  > footer {
    width: 100%;
    position: relative;

    .btn {
      width: 300px;
      height: 50px;
      border-radius: 25px;
      background-image: linear-gradient(90deg, #74ead5 0px 50px, #9eabe4 250px 300px);
      line-height: 50px;
      color: white;
      font-size: 30px;
      user-select: none;
    }
    /deep/ .checkbox {
      color: #74ead5;
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #74ead5 !important;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #74ead5;
      }
    }
    .register {
      user-select: none;
      color: #74ead5;
      font-size: 16px;
    }
  }
}
</style>
