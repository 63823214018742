// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入主题文件
import './echarts/theme';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  SVGRenderer
]);
// 引入axios
import axios from 'axios'
axios.defaults.headers.common['authorization'] = localStorage.getItem("authorization");
axios.defaults.baseURL = 'https://www.fantasyfuturex.com/'
const Api = {
  install() {
    this.prototype.axios = axios
    this.prototype.echarts = echarts
    this.prototype.push = Api.push
    this.prototype.pop = Api.pop
  },
  // 路由跳转
  push(param) {
    this.$router.push(param);
  },
  // 返回
  pop() {
    this.$router.go(-1);
  },
}
const mixin = {

}
export default Api
export { Api, mixin }