<template>
  <div class="logs w100 pd-20">
    <div id="click_logs"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      click_logs: null,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    // 接下来的使用就跟之前一样，初始化图表，设置配置项
    let opt = {
      renderer: "canvas", // 设置渲染器 svg | canvas ; 默认 canvas
      useDirtyRect: true, // 开启脏矩形渲染,只在canvas渲染模式有效
      useCoarsePointer: null, // 是否扩大可点击元素的响应范围。null 表示对移动设备开启；true 表示总是开启；false 表示总是不开启
      locale: "ZH", // 使用的语言，内置 'ZH' 和 'EN' 两个语言
    };
    let click_logs = this.echarts.init(
      document.getElementById("click_logs"),
      "gray",
      opt
    );
    window.onresize = function () {
      //监听图表容器的大小并改变图表大小
      click_logs.resize();
    };
    let option = {
      legend: {},
      tooltip: {},
      dataset: {
        source: [
          ["score", "amount", "product"],
          [89.3, 58212, "Matcha Latte"],
          [57.1, 78254, "Milk Tea"],
          [74.4, 41032, "Cheese Cocoa"],
          [50.1, 12755, "Cheese Brownie"],
          [89.7, 20145, "Matcha Cocoa"],
          [68.1, 79146, "Tea"],
          [19.6, 91852, "Orange Juice"],
          [10.6, 101852, "Lemon Juice"],
          [32.7, 20112, "Walnut Brownie"],
        ],
      },
      xAxis: {},
      yAxis: { type: "category" },
      series: [
        {
          type: "bar",
          encode: {
            // 将 "amount" 列映射到 X 轴。
            x: "amount",
            // 将 "product" 列映射到 Y 轴。
            y: "product",
            tooltip: [0, 1],
          },
        },
      ],
    };
    option && click_logs.setOption(option);
  },
  methods: {
    getData() {
      this.axios
        .post("/bookmark/click_log", {
          authorization: localStorage.getItem("authorization"),
        })
        .then((res) => {
          if (res.data.code === 202) console.log("post_err:", res.data.msg);
          else {
            let data = this.order(res.data.data);
            this.click_logs = data;
            console.log(data);
          }
        });
    },
    order(data) {
      let step = Math.floor(data.length / 2);
      do {
        for (let i = step; i < data.length; i++) {
          let temp = data[i];
          let j = i - step;
          for (
            j;
            j >= 0 && data[j].click_log.length < temp.click_log.length;
            j -= step
          ) {
            data[j + step] = data[j];
          }
          data[j + step] = temp;
        }
        step = Math.floor(step / 2);
      } while (step > 0);
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.logs {
  background-color: black;
  min-width: 100vw;
  min-height: 100vh;
  > #click_logs {
    width: calc(50vw - 30px);
    height: calc(25vw - 15px);
  }
}
</style>
