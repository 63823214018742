import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    uid: null,
    uname: null,
    avatar: null,
    authorization: null,
  },
  getters: {},
  mutations: {
    updateState(state, data) {
      for (let i = 0; i < data.length; i++) {
        state[data[i].key] = data[i].value;
      }
    },
    SET_AUTHORIZATION(state, val) {
      state.authorization = val;
    },
  },
  actions: {},
  modules: {},
});
