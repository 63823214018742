<template>
  <div class="register_toast w100 h100" @click.stop="">
    <div class="register" @click.stop="">
      <header>Register</header>
      <form>
        <div class="user">
          <i class="iconfont icon-user"></i>
          <div class="inp">
            <el-input
              autocomplate="off"
              ref="uname"
              v-model="form.uname"
              type="text"
              :maxlength="16"
              placeholder="Username"
              @blur="goCheck('uname')"
              @focus="focus('uname')"
              @keyup.enter.native="register()" />
            <i v-if="!check.uname" @click="clean('uname')" class="iconfont icon-err"></i>
          </div>
          <div class="error" v-if="msg.uname">
            {{ msg.uname }}
          </div>
        </div>
        <div class="pwd">
          <i class="iconfont icon-pwd"></i>
          <div class="inp">
            <el-input
              class="pwd"
              auto-complate="off"
              ref="upwd"
              v-model="form.upwd"
              type="text"
              placeholder="Password"
              @blur="goCheck('upwd')"
              @focus="focus('upwd')"
              @keyup.enter.native="register()" />
            <i v-if="!check.upwd" @click="clean('upwd')" class="iconfont icon-err"></i>
          </div>
          <div class="error" v-if="msg.upwd">
            {{ msg.upwd }}
          </div>
        </div>
        <div class="pwd_">
          <i class="iconfont icon-pwd"></i>
          <div class="inp">
            <el-input
              class="pwd"
              autocomplate="off"
              ref="upwd_"
              v-model="form.upwd_"
              type="text"
              placeholder="PasswordAgain"
              @blur="goCheck('upwd_')"
              @focus="focus('upwd_')"
              @keyup.enter.native="register()" />
            <i v-if="!check.upwd_" @click="clean('upwd_')" class="iconfont icon-err"></i>
          </div>
          <div class="error" v-if="msg.upwd_">
            {{ msg.upwd_ }}
          </div>
        </div>
        <div class="email">
          <i class="iconfont icon-email"></i>
          <div class="inp">
            <el-input
              ref="email"
              :disabled="useable"
              v-model="form.email"
              type="text"
              placeholder="Email"
              @focus="focus('email')"
              @keyup.enter.native="register()" />
            <i v-if="!check.email" @click="clean('email')" class="iconfont icon-err"></i>
          </div>
          <div class="error" v-if="msg.email">
            {{ msg.email }}
          </div>
          <div class="btn">
            <i v-if="!timeOut" @click="getCode">获取验证码</i>
            <i v-else>已发送&emsp;{{ timeOut }}</i>
          </div>
        </div>
        <div class="code">
          <i class="iconfont icon-security"></i>
          <div class="inp">
            <el-input
              ref="security"
              v-model="form.security"
              type="text"
              placeholder="SecurityCode"
              @blur="goCheck('security')"
              @focus="focus('security')"
              @keyup.enter.native="register()" />

            <i v-if="!check.security" @click="clean('security')" class="iconfont icon-err"></i>
          </div>
          <div class="error" v-if="msg.security">
            {{ msg.security }}
          </div>
        </div>
      </form>
      <footer>
        <div class="btn" @click="register">SignUp</div>
        <div class="login" @click.stop="login">LogIn</div>
      </footer>
    </div>
  </div>
</template>

<script>
import { throttle } from "@/assets/Utils";
export default {
  data() {
    return {
      form: {
        uname: "",
        upwd: "",
        upwd_: "",
        email: "",
        security: "",
      },
      check: {
        uname: true,
        upwd: true, // 密码,用户名,验证码是否有误
        upwd_: true,
        email: true,
        security: true,
      },
      regexp: {
        uname: /^[a-zA-Z0-9_-]{4,16}$/,
        upwd: /^.*(?=.{6,})(?=.*[A-Za-z]).*$/, // 字母加数字
        email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      },
      msg: {
        uname: null,
      },
      timeOut: 0,
      token: null,
      data: {}, // 获取邮箱验证码,将邮箱存入data, 最终使用data注册
    };
  },
  computed: {
    useable() {
      return Boolean(this.timeOut);
    },
  },
  methods: {
    register() {
      (async () => {
        let can_register = true;
        for (let [key, value] of Object.entries(this.form)) {
          if (key !== "email") {
            try {
              await this._check(key);
              if (key !== "upwd_") this.data[key] = value.trim();
            } catch (err) {
              can_register = false;
              break;
            }
          }
        }
        if (can_register) {
          if (!this.token || !this.data.email) {
            this.check.email = false;
            this.msg.email = "请输入邮箱并获取验证码";
          } else {
            this.axios
              .post("/user/signIn", { data: this.data, token: this.token })
              .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                  this.$parent.showRegister = false;
                  this.$parent.showLogin = true;
                  this.$message({
                    message: "注册成功,请登录",
                    type: "success",
                  });
                } else if (res.data.code === 202) {
                  this.check[res.data.type] = false;
                  this.msg[res.data.type] = res.data.msg;
                } else {
                  console.log(res);
                  this.$message.error("注册失败,请稍后重试");
                }
              })
              .catch(err => {
                console.log(err);
                this.$message.error("注册失败,请稍后重试");
              });
            console.log("开始注册");
          }
        }
      })();
    },
    getCode: throttle(function () {
      this.check.email = true;
      this.msg.email = null;
      if (this.regexp.email.test(this.form.email)) {
        this.timeOut = 60;
        this.timer = setInterval(() => {
          this.timeOut -= 1;
          if (!this.timeOut) clearInterval(this.timer);
        }, 1000);
        this.axios
          .post("/user/verifyEmail", { email: this.form.email })
          .then(res => {
            if (res.data.code === 200) {
              this.token = res.data.token;
              this.data.email = res.data.email;
            } else {
              console.log(res);
              this.check.email = false;
              clearInterval(this.timer);
              this.timeOut = 0;
              this.$message.error("验证码发送失败");
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.check.email = false;
        this.msg.email = "邮箱格式不正确";
      }
    }),
    _check(type) {
      return new Promise((resolve, reject) => {
        const msg = {
          uname: "4到16位(字母,数字,下划线,减号)",
          upwd: "最少6位,需包含字母",
          upwd_: "两次输入密码不一致",
          security: "请获取邮箱验证码",
        };
        let result = false;
        if (type === "upwd_") {
          result = this.form[type].trim() === this.form.upwd.trim();
        } else if (type === "security") {
          result = this.form.security.trim().length;
        } else {
          result = this.regexp[type].test(this.form[type].trim());
        }
        if (!result) {
          this.msg[type] = msg[type];
          this.check[type] = false;
          reject(`check ${type} err`);
        } else resolve(`check ${type} ok`);
      });
    },
    goCheck(type) {
      this._check(type)
        .then(res => {})
        .catch(err => {});
    },
    clean(type) {
      this.form[type] = "";
      this.msg[type] = null;
      this.check[type] = true;
    },
    focus(type) {
      this.msg[type] = null;
      this.check[type] = true;
    },
    login() {
      this.$parent.showRegister = false;
      this.$parent.showLogin = true;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .register_toast {
    .register {
      width: 800px;
      padding: 20px;
      border-radius: 20px;
      > header {
        font-size: 60px;
        padding-bottom: 20px;
      }
      form {
        padding-bottom: 30px;
        > div {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
          &.email {
            > .btn {
              padding-left: 20px;
              width: 120px;
              height: 44px;
              font-size: 20px;
            }
          }
          > .inp {
            > .el-input {
              ::v-deep(input) {
                font-size: 30px;
              }
            }
            > i {
              font-size: 30px;
            }
          }
          > .error {
            left: 60px;
            top: 50px;
            font-size: 18px;
          }
        }
      }
      > footer {
        .btn {
          width: 300px;
          font-size: 30px;
        }
        .login {
          right: 60px;
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .register_toast {
    .register {
      width: 94.6667vw;
      padding: 2.6667vw;
      padding-bottom: 8vw;
      border-radius: 2.6667vw;
      > header {
        font-size: 8vw;
        padding-bottom: 2.6667vw;
      }
      form {
        padding-bottom: 4vw;
        > div {
          margin-bottom: 2.6667vw;
          &:last-child {
            margin-bottom: 0vw;
          }
          &.email {
            > .btn {
              padding-left: 2.6667vw;
              width: 16vw;
              height: 5.8667vw;
              font-size: 2.6667vw;
            }
          }
          > .inp {
            > .el-input {
              ::v-deep(input) {
                font-size: 4vw;
              }
            }
            > i {
              font-size: 2.6667vw;
            }
          }
          > .error {
            left: 60px;
            top: 50px;
            font-size: 2.4vw;
          }
        }
      }
      > footer {
        .btn {
          width: 80vw;
          font-size: 4vw;
        }
        .login {
          right: 50%;
          transform: translateX(50%);
          bottom: -6.6667vw;
          font-size: 16px;
        }
      }
    }
  }
}
.register_toast {
  position: fixed;
  top: 0;
  left: 0;
  .register {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(80, 80, 80, 0.6);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    > header {
      color: white;
      font-weight: bolder;
    }
    form {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 100%;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        > i {
          color: white;
          font-size: 40px;
          padding-right: 20px;
        }
        > .inp {
          border-bottom: 4px solid white;
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          > .el-input {
            ::v-deep(input) {
              background-color: transparent;
              color: white;
              border: 0;
            }
            width: 100%;
            height: 100%;
            ::v-deep(input::placeholder) {
              color: rgba(200, 200, 200, 1);
            }
            ::v-deep(input::-webkit-input-placeholder) {
              color: rgba(200, 200, 200, 1);
            }
            ::v-deep(input:-webkit-autofill) {
              box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.8) inset; // 背景颜色
              background-color: transparent;
              -webkit-text-fill-color: #fff; // input 的文字的颜色
              border: 0;
            }
          }
          > .pwd {
            text-security: disc !important;
            -webkit-text-security: disc !important;
          }
          > i {
            color: red;
          }
        }
        > .error {
          position: absolute;
          font-weight: 600;
          color: #ff0700;
        }
        &.email {
          > .btn {
            box-sizing: content-box;
            flex-shrink: 0;
            display: flex;
            color: #9eabe4;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
    > footer {
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      .btn {
        height: 50px;
        border-radius: 25px;
        background-image: linear-gradient(90deg, #74ead5 0px 50px, #9eabe4 250px 300px);
        line-height: 50px;
        color: white;
        user-select: none;
      }
      .login {
        position: absolute;
        user-select: none;
        color: #74ead5;
      }
    }
  }
}
</style>
